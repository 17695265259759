import * as React from 'react'

import { Container } from '../../shared/Grid'
import { Box, Flex } from '../../shared/Helpers'
import { Image } from '../../shared/Image'
import Link from '../../shared/Link'
import { Description, Title } from '../../shared/Typography'
import { ISection } from './types'

interface IProps {
  data: ISection
}

export default function renderSectionProjects({ data }: IProps) {
  return (
    <Container>
      <Flex
        flexWrap={['wrap', 'wrap', 'nowrap']}
        p={[0, '0 6em']}
        mb="8em"
        alignItems="center"
      >
        <Box width={['100%', '100%', '55%']} pr={[0, '3em']}>
          <Title mb="0.5em">{data.title}</Title>
          <Description mb="1em" color="greyMedium">
            {data.description}
          </Description>
          <Link color="blue" to={data.ctaLink} withIcon="arrow">
            {data.ctaText}
          </Link>
        </Box>
        <Box width={['100%', '100%', '45%']}>
          <Image
            style={{ maxWidth: '384px' }}
            path={data.image}
            alt={data.title}
          />
        </Box>
      </Flex>
    </Container>
  )
}
