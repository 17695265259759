import * as React from 'react'

import LatestPosts from '../components/LatestPosts'
import Newsletter from '../components/Newsletter'
import Hero from '../components/ProjectsWeLove/Hero'
import Projects from '../components/ProjectsWeLove/ProjectsList'
import Contact from '../components/ProjectsWeLove/Section'
import SEO from '../components/SEO'
import Layout from '../layouts'

const ProjectWeLove: React.FC = () => {
  return (
    <Layout>
      <SEO />
      <Hero />
      <Projects />
      <Contact />
      <LatestPosts message="projects" />
      <Newsletter />
    </Layout>
  )
}

export default ProjectWeLove
