import * as React from 'react'

import Card from '../../shared/Card'
import { Container } from '../../shared/Grid'
import { Flex } from '../../shared/Helpers'
import { Image as Thumb } from '../../shared/Image'
import Link from '../../shared/Link'
import { Caption, Text } from '../../shared/Typography'
import styled, { css } from '../../styled'
import { IList, IProjects } from './types'

interface IProps {
  data: IProjects
}
const ImageWrapper = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 4px;
`
const FlexColumn = styled(Flex)`
  flex-flow: row wrap;
  justify-content: space-between;

  &:after {
    content: '';

    ${({ theme: { breakpoints } }) => css`
      @media screen and (min-width: ${breakpoints[0]}) {
        display: none;
      }

      @media screen and (min-width: ${breakpoints[1]}) {
        display: block;
        width: calc((100% / 2) - 0.7em);
      }
    `}
  }
`

export default function renderValuesAbout({ data: { list } }: IProps) {
  return (
    <Container zIndex={3}>
      <FlexColumn mt={['2.5rem', '4em']} mb={'6em'}>
        {list.map(renderCards)}
      </FlexColumn>
    </Container>
  )
}

function renderCards({ title, description, image, ctaText, link }: IList) {
  const width = ['100%', 'calc((100% / 2) - .7em)']
  return (
    <Card mb="1.5em" width={width}>
      <Flex>
        <Flex mb="1rem" alignItems="center">
          <ImageWrapper>
            <Thumb path={image} alt={title} />
          </ImageWrapper>
          <Caption ml=".7em">{title}</Caption>
        </Flex>
      </Flex>
      <Text mb="1.5rem" color="grey">
        {description}
      </Text>
      <Link color="blue" to={link} withIcon="arrow">
        {ctaText}
      </Link>
    </Card>
  )
}
