import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'

import mergeEdges from '../../../utils/mergeEdges'
import { IProp } from './types'
import View from './view'

export default function querySectionProjectsWeLove() {
  return (
    <StaticQuery
      query={graphql`
        query ProjectsList {
          allFoundationYaml {
            edges {
              node {
                projectsWeLove {
                  list {
                    title
                    description
                    image
                    ctaText
                    link
                  }
                }
              }
            }
          }
        }
      `}
      render={({ allFoundationYaml }: IProp) => {
        const data = mergeEdges(allFoundationYaml)

        if (!data) {
          return null
        }

        return <View data={data.projectsWeLove} />
      }}
    />
  )
}
